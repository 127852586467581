const routes = {
  // home
  home: '/',

  // mypage
  myPage: (userPublicAddress: string): string => `/users/${userPublicAddress}`,
  accountSettings: '/account/settings',
  twitterSettings: '/account/settings/twitter',

  // limited collections
  limitedCollections: '/limited-collections',
  limitedCollectionDetail: (tokenID: string): string =>
    `/limited-collections/${tokenID}`,

  // creations
  createCreation: '/creations/create',
  creationDetail: (creationID: number | string): string =>
    `/creations/${creationID}`,
  sellCreation: (creationID: number | string): string =>
    `/creations/${creationID}/sell`,
  editCreation: (creationID: number | string): string =>
    `/creations/${creationID}/edit`,

  // marketplace
  marketplace: '/marketplace',
  marketplaceWithTags: ({ tagName, tagNameJA }) =>
    `/marketplace?tagName=${tagName}&tagNameJA=${tagNameJA}`,
  creatorDetail: (userName: string): string => `/@${userName}`,
  tokenDetail: (aniftyTokenID: number | string): string =>
    `/tokens/${aniftyTokenID}`,

  // transaction
  transactionDetail: (transactionHash: string): string =>
    `/transactions/${transactionHash}`,

  // notifications
  notifications: '/notifications',

  // auction activities
  auctionActivities: '/activities',

  // connect wallet
  connectWallet: '/connect-wallet',
};

export default routes;
