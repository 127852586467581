import { useMemo } from 'react';

import { AskStatus } from '@/apis/aniftyBackend/marketplaceV2/interface';
import { useMintAsks } from '@/apis/aniftyBackend/mintAsks/getMintAsks';
import { useCurrentUserMintBids } from '@/apis/aniftyBackend/mintBids/getCurrentUserMintBids';

export const useActivityCount = (): {
  activityCount: number;
} => {
  // mint asks
  const { data: mintAsks } = useMintAsks();

  // mint bids
  const { data: mintBids } = useCurrentUserMintBids();

  const activityCount = useMemo(() => {
    // filter only active asks
    const activeMintAsks = (mintAsks || []).filter(
      (mintAsk) => mintAsk.status === AskStatus.Ongoing
    );

    // filter only active bids
    const activeMintBids = (mintBids || []).filter(
      (mintBid) => mintBid.askStatus === AskStatus.Ongoing
    );

    // use set to remove ask duplicates
    const bidCreatedAskHashes = new Set(
      activeMintBids.map((mintBid) => mintBid.askHash)
    );

    return activeMintAsks.length + bidCreatedAskHashes.size;
  }, [mintAsks, mintBids]);

  return {
    activityCount,
  };
};
