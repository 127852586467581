import definedOrThrow from '@/utils/definedOrThrow';

/**
 * Contract addresses
 */

export const addresses = {
  AniftyERC1155V1: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_ERC1155_V1,
    'NEXT_PUBLIC_ANIFTY_ERC1155_V1'
  ),
  AniftyERC1155V2: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_ERC1155_V2,
    'NEXT_PUBLIC_ANIFTY_ERC1155_V2'
  ),

  AniftyCollection: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_COLLECTION_CONTRACT,
    'NEXT_PUBLIC_ANIFTY_COLLECTION_CONTRACT'
  ),

  AniftyMarketplaceV1: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V1,
    'NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V1'
  ),
  AniftyMarketplaceV2: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V2,
    'NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V2'
  ),
  AniftyMarketplaceV3: definedOrThrow(
    process.env.NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V3,
    'NEXT_PUBLIC_ANIFTY_MARKETPLACE_CONTRACT_V3'
  ),

  WrappedETH: definedOrThrow(
    process.env.NEXT_PUBLIC_WETH_CONTRACT,
    'NEXT_PUBLIC_WETH_CONTRACT'
  ),
};
