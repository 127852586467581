import { useTranslation } from 'next-i18next';
import { VFC } from 'react';

import useGetStaticSiteUrl from '@/hooks/useGetStaticSiteUrl';
import DiscordIcon from '@/icons/discord.svg';
import MediumIcon from '@/icons/medium.svg';
import TelegramIcon from '@/icons/telegram.svg';
import TwitterIcon from '@/icons/twitter.svg';

import styles from './Footer.module.scss';

const Footer: VFC = () => {
  const { t } = useTranslation();
  const getStaticUrl = useGetStaticSiteUrl();

  return (
    <>
      <div className={styles.footerTxt}>Find us on</div>
      <div className={styles.footerSnsList}>
        <a
          href="https://twitter.com/anifty_jp"
          target="_blank"
          className="footerSnsList__item" rel="noreferrer"
        >
          <TwitterIcon />
        </a>
        <a
          href="https://discord.com/invite/jHHmxv5Xnb"
          target="_blank"
          className="footerSnsList__item" rel="noreferrer"
        >
          <DiscordIcon />
        </a>
        <a
          href="https://t.me/joinchat/gOJFYPx57rI5MjA1"
          target="_blank"
          className="footerSnsList__item" rel="noreferrer"
        >
          <TelegramIcon />
        </a>
        <a
          href="https://anifty-jp.medium.com/"
          target="_blank"
          className="footerSnsList__item" rel="noreferrer"
        >
          <MediumIcon />
        </a>
      </div>
      <footer className={styles.footerLinkArea}>
        <a href={getStaticUrl('company')}>{t('footer.companyInformation')}</a>
        <a href={getStaticUrl('terms')}>{t('footer.terms')}</a>
        <a href={getStaticUrl('law')}>{t('footer.legalNotice')}</a>
        <a href={getStaticUrl('privacy')}>{t('footer.privacyPolicy')}</a>
        <a href={getStaticUrl('policy')}>{t('footer.personalInfo')}</a>
        <a href={getStaticUrl('form')}>{t('footer.contact')}</a>
        <a href={getStaticUrl('corp')}>{t('footer.businessContact')}</a>
        <a href={getStaticUrl('artistguide/#guide06')}>
          {t('footer.guidelines')}
        </a>
        <p className="footer__copyright">© ANIFTY Co., Ltd.</p>
      </footer>
    </>
  );
};

export default Footer;
