import client from '@/apis/aniftyBackend/client';
import { PrimarySaleItemData } from '@/apis/aniftyBackend/marketplace/interface';
import { SearchConditionNFTs } from '@/containers/MarketplacePage/interface';

export const MarketplaceCreationStatus = {
  All: 'all',
  Available: 'available',
  Sold: 'sold',
} as const;

export type MarketplaceCreationStatus =
  typeof MarketplaceCreationStatus[keyof typeof MarketplaceCreationStatus];

export const SortParam = {
  ListedAtDesc: '-listedAt',
  ListedAtAsc: '+listedAt',
  CreatedAtDesc: '-createdAt',
  CreatedAtAsc: '+createdAt',
  PriceDesc: '-price',
  PriceAsc: '+price',
} as const;

export type SortParam = typeof SortParam[keyof typeof SortParam];

export type GetCreationsParams = {
  sort?: SortParam;
  limit?: number;
  offset?: number;

  tag_id?: number[] | null;
  // receive ETH price in WEI
  price_min?: string | null;
  price_max?: string | null;
  status?: MarketplaceCreationStatus;
};

type PaginationProps = {
  limit: number;
  offset: number;
};

const fetchCreations = async (
  { keyword, tagNames, priceMin, priceMax, status, sort }: SearchConditionNFTs,
  pagination: PaginationProps = null
): Promise<PrimarySaleItemData[]> => {
  // `keyword` param
  const params = new URLSearchParams();
  if (keyword) {
    params.append('keyword', keyword);
  }

  // add `tag_name` param if `tagNames` specified
  if (tagNames && tagNames.length) {
    tagNames.forEach((tagName) => {
      params.append('tag_name[]', tagName.toString());
    });
  }

  // `price_min` param
  if (priceMin) {
    params.append('price_min', priceMin.toString());
  }

  // `price_max` param
  if (priceMax) {
    params.append('price_max', priceMax.toString());
  }

  // `status` param
  if (status && status !== 'all') {
    params.append('status', status);
  }

  // `sort` param
  if (sort) {
    params.append('sort', sort);
  }

  // `limit` & `offset` param
  if (pagination) {
    params.append('limit', pagination.limit.toString());
    params.append('offset', pagination.offset.toString());
  }

  const response = await client.get<PrimarySaleItemData[]>(`api/v2/creations`, {
    params,
  });

  return response.data;
};

export default fetchCreations;
