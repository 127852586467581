import Script from 'next/script';
import React, { FC } from 'react';

import { usePageView } from '@/hooks/usePageView';
import { useUserTypeSetter } from '@/hooks/useUserTypeSetter';
import { GA4_MEASUREMENT_ID, GA_TRACKING_ID } from '@/utils/gtag';

/**
 * Tracking tag for Google Analytics
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs
 * @see https://nextjs.org/docs/messages/next-script-for-ga
 */

export const GATrackingTag: FC = () => {
  // Submit page view to GA on route change
  usePageView();

  // Set user type on user load
  useUserTypeSetter();

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${
          GA4_MEASUREMENT_ID || GA_TRACKING_ID
        }`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          ${GA_TRACKING_ID ? `gtag('config', '${GA_TRACKING_ID}');` : ''}
          ${
            GA4_MEASUREMENT_ID ? `gtag('config', '${GA4_MEASUREMENT_ID}');` : ''
          }
        `}
      </Script>
    </>
  );
};
