import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';

import fetchUserProfileByID from '@/apis/aniftyBackend/users/fetchUserProfileByID';
import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';

export type CurrentUserProfile = {
  userID: string;
  typeID: number;
  isDiscounted: boolean;
  loading: boolean;
  reload(): void;
  initialized: boolean;
};

export const ProfileContext =
  createContext<CurrentUserProfile | null>(undefined);

type Props = {
  children: ReactNode;
};

const ProfileProvider: VFC<Props> = ({ children }: Props) => {
  const web3Context = useContext(Web3Context);

  const [typeID, setTypeID] = useState<undefined | number | null>(undefined);
  const [isDiscounted, setIsDiscounted] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);

  const loadProfile = async () => {
    if (!web3Context.currentAccountAddress) {
      return;
    }
    try {
      const userData = await fetchUserProfileByID(
        web3Context.currentAccountAddress
      );
      setTypeID(userData.typeID);
      setIsDiscounted(userData.creatorProfile.isDiscounted);
      setInitialized(true);
    } catch (err) {
      setTypeID(null);
    }
  };

  useEffect(() => {
    loadProfile();
  }, [web3Context.currentAccountAddress]);

  return (
    <ProfileContext.Provider
      value={{
        userID: web3Context.currentAccountAddress,
        typeID,
        isDiscounted,
        loading: typeof typeID === 'undefined',
        reload: loadProfile,
        initialized: initialized,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
