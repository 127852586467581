import { useContext } from 'react';

import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';

const useWalletAddress = (): {
  currentAccountAddress: string | null;
  loading: boolean;
} => {
  const { currentAccountAddress, web3 } = useContext(Web3Context);

  return {
    currentAccountAddress: currentAccountAddress?.toLowerCase() || null,
    loading: !web3.initialized,
  };
};

export default useWalletAddress;
