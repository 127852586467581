import { BigNumber, Contract, ethers, providers, Transaction } from 'ethers';

import AniftyCollectionAbi from '@/abis/AniftyCollection';
import { CollectionInfo } from '@/apis/blockchain/interface';
import { addresses } from '@/constants/addresses';

export const getAniftyCollectionContract = async (
  provider: providers.Provider | providers.JsonRpcSigner
): Promise<Contract> => {
  return new ethers.Contract(
    addresses.AniftyCollection,
    AniftyCollectionAbi,
    provider
  );
};

// getters
export const getAniftyERC1155 = async (
  tokenContract: Contract
): Promise<string> => {
  return await tokenContract.aniftyERC1155();
};

export const getRoundId = async (
  tokenContract: Contract
): Promise<BigNumber> => {
  return await tokenContract.roundId();
};

export const getRoundCollection = async (
  tokenContract: Contract,
  roundId: BigNumber | string | number,
  collectableIndex: BigNumber | string | number
): Promise<CollectionInfo> => {
  return await tokenContract.roundCollection(roundId, collectableIndex);
};

export const getRoundCollections = async (
  tokenContract: Contract,
  roundId: BigNumber | string | number
): Promise<CollectionInfo[]> => {
  const collectablesAmount = 9;
  const promises = [];

  for (let i = 0; i < collectablesAmount; i++) {
    promises.push(await tokenContract.roundCollection(roundId, i));
  }

  return Promise.all(promises);
};

// setters
export const buyCollectable = async (
  tokenContract: Contract,
  collectableIndex: number,
  NFTPurchaseAmount: BigNumber | string | number,
  ETHAmtMantissa: BigNumber
): Promise<Transaction> => {
  // checker for contract existence to avoid accidentally sending ETH
  const AniftyERC1155Address = await tokenContract.aniftyERC1155();
  if (AniftyERC1155Address !== addresses.AniftyERC1155V1) {
    throw Error('Contract does not exist!');
  }

  const buyCollectableTx = await tokenContract.buyCollectable(
    //to be edited later. collectable index should be stored in db
    collectableIndex - 1,
    NFTPurchaseAmount,
    {
      value: ETHAmtMantissa.mul(NFTPurchaseAmount),
    }
  );
  return buyCollectableTx;
};
