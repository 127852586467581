import { useContext } from 'react';

import { User } from '@/apis/aniftyBackend/users/interface';
import CurrentUserContext from '@/contexts/CurrentUserContext';

const useCurrentUser = (): {
  user: User | null;
  loading: boolean;
  reload(): void;
} => {
  const currentUserContext = useContext(CurrentUserContext);

  return {
    loading: currentUserContext.loading,
    user: currentUserContext.currentUser,
    reload: currentUserContext.reload,
  };
};

export default useCurrentUser;
