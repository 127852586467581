import { BigNumber, Contract, ethers, providers } from 'ethers';

import WETHabi from '@/abis/WETHAbi';
import { addresses } from '@/constants/addresses';

export const getWrappedETHContract = async (
  provider: providers.Provider | providers.JsonRpcSigner
): Promise<Contract> => {
  return new ethers.Contract(addresses.WrappedETH, WETHabi, provider);
};

export const getAllowance = async (
  tokenContract: Contract,
  accountAddress: string,
  operatorAddress: string
): Promise<BigNumber> => {
  return await tokenContract.allowance(accountAddress, operatorAddress);
};

export const getWETHBalance = async (
  tokenContract: Contract,
  accountAddress: string
): Promise<BigNumber> => {
  return await tokenContract.balanceOf(accountAddress);
};

export const setAllowanceApproval = async (
  tokenContract: Contract,
  operatorAddress: string,
  approvalAmount: BigNumber
): Promise<BigNumber> => {
  return await (
    await tokenContract.approve(operatorAddress, approvalAmount)
  ).wait();
};
