import client from '@/apis/aniftyBackend/client';

const signInUserByID = async (userID: string): Promise<void> => {
  const response = await client.post(`/api/users`, {
    publicAddress: userID,
  });

  return response.data;
};

export default signInUserByID;
