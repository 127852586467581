import { useEffect } from 'react';

import useCurrentUser from '@/hooks/useCurrentUser';
import { gaSetUserType } from '@/utils/gtag';

/**
 * This hook sets `User Type` custom dimension to Google Analytics
 * when current user data is loaded
 */

type UserType = 'creator' | 'collector';

export const useUserTypeSetter = (): void => {
  const { user } = useCurrentUser();

  useEffect(() => {
    // Set userType when current user is loaded
    if (user) {
      const userType: UserType = user.typeID === 2 ? 'creator' : 'collector';
      gaSetUserType(userType);
    }
  }, [user]);
};
