/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  VFC,
} from 'react';

import fetchCreations from '@/apis/aniftyBackend/creations/fetchCreations';
import fetchUsers from '@/apis/aniftyBackend/creations/fetchUsers';
import { fetchNumCreations } from '@/apis/aniftyBackend/creations/fetchNumCreations';
import { FilteredUsers } from '@/apis/aniftyBackend/creations/interface';
import { PrimarySaleItemData } from '@/apis/aniftyBackend/marketplace/interface';
import {
  MARKETPLACE_ITEMS_PER_PAGE,
  SearchConditionNFTs,
  SearchConditionUsers,
  SortParam,
  SortParamProfile,
  RandomCreationItem,
} from '@/containers/MarketplacePage/interface';
import { captureException } from '@/utils/Sentry';
import HIDDEN_USER_IDS from '@/constants/hiddenUserIDs';
import fetchCreationsByCreationIDs from '@/apis/aniftyBackend/creations/fetchCreationsByCreationIDs';
import { useCurrentPageCache } from '@/hooks/useCurrentPageCache';
export type Status =
  | 'initializing'
  | 'hasMore'
  | 'loadingMore'
  | 'loadedAll'
  | 'hasError';

type MarketplaceItemsContext = {
  initialSearchConditionNFTs;
  initialSearchConditionUsers;
  creations: PrimarySaleItemData[];
  setCreations(creations: PrimarySaleItemData[]): void;
  randomCreations: RandomCreationItem[];
  setRandomCreations(creations: RandomCreationItem[]): void;
  userStatus: Status;
  NFTStatus: Status;
  setUserStatus(status: Status): void;
  setNFTStatus(status: Status): void;
  searchConditionNFTs: SearchConditionNFTs;
  setSearchConditionNFTs(getCreationsParams: SearchConditionNFTs): void;
  loadCreations(clearExisting?: boolean, randomize?: boolean): void;
  loadRandomCreations(): void;
  searchConditionUsers: SearchConditionUsers;
  setSearchConditionUsers(getUserParams: SearchConditionUsers): void;
  filteredUsers: FilteredUsers[];
  setFilteredUsers(Users: FilteredUsers[]): void;
  loadUsers(clearExisting?: boolean): void;
  isLoaded: boolean;
  setIsLoaded(loaded: boolean): void;
  showRandom: boolean;
  setShowRandom(show: boolean): void;
};

const initialSearchConditionNFTs: SearchConditionNFTs = {
  keyword: '',
  tagNames: [],
  tagNamesJA: [],
  sort: SortParam.ListedAtDesc,
  status: 'all',
  priceMin: null,
  priceMax: null,
};

const initialSearchConditionUsers: SearchConditionUsers = {
  keyword: '',
  tagNames: [],
  tagNamesJA: [],
  sort: SortParamProfile.creationCountAsc,
  verification: 'all',
};

const MarketplaceItemsContext = createContext<MarketplaceItemsContext>({
  initialSearchConditionNFTs,
  initialSearchConditionUsers,
  creations: [],
  randomCreations: [],
  NFTStatus: 'initializing',
  userStatus: 'initializing',
  searchConditionNFTs: initialSearchConditionNFTs,
  searchConditionUsers: initialSearchConditionUsers,
  filteredUsers: [],
  setCreations: () => {},
  setRandomCreations: () => {},
  setNFTStatus: () => {},
  setUserStatus: () => {},
  setSearchConditionNFTs: () => {},
  setSearchConditionUsers: () => {},
  loadCreations: () => {},
  loadRandomCreations: () => {},
  setFilteredUsers: () => {},
  loadUsers: () => {},
  isLoaded: false,
  setIsLoaded: () => {},
  showRandom: false,
  setShowRandom: () => {},
});

type Props = {
  children: ReactNode;
};
export const MarketPlaceItemsProvider: VFC<Props> = ({ children }: Props) => {
  const [creations, setCreations] = useState<PrimarySaleItemData[]>([]);
  const [randomCreations, setRandomCreations] = useState<RandomCreationItem[]>(
    []
  );
  const [NFTStatus, setNFTStatus] = useState<Status>('initializing');
  const [userStatus, setUserStatus] = useState<Status>('initializing');
  const [filteredUsers, setFilteredUsers] = useState<FilteredUsers[]>([]);
  const [searchConditionNFTs, setSearchConditionNFTs] =
    useState<SearchConditionNFTs>(initialSearchConditionNFTs);
  const [searchConditionUsers, setSearchConditionUsers] =
    useState<SearchConditionUsers>(initialSearchConditionUsers);
  //se
  const [isLoaded, setIsLoaded] = useState(false);
  const [showRandom, setShowRandom] = useState(false);

  const { getCacheContent: getUsersCache, invalidate: invalidateUsers } =
    useCurrentPageCache();

  const {
    getCacheContent: getCreationsCache,
    invalidate: invalidateCreations,
  } = useCurrentPageCache();

  useEffect(() => {
    invalidateUsers();
  }, [searchConditionUsers.keyword]);

  useEffect(() => {
    invalidateCreations();
  }, [searchConditionNFTs.keyword]);

  const loadUsers = async (clearExisting = false): Promise<void> => {
    if (userStatus === 'loadingMore') {
      return;
    }

    setUserStatus(clearExisting ? 'initializing' : 'loadingMore');

    // Set offset
    const offset = clearExisting ? 0 : filteredUsers.length;

    try {
      // Call API without pagination
      const fetchParameters = {
        searchConditionUsers,
        pagination: {
          limit: MARKETPLACE_ITEMS_PER_PAGE + 1, // Request 1 more item to check if there are more items
          offset,
        },
      };
      let loadedItems = (await getUsersCache(
        JSON.stringify(fetchParameters),
        () => fetchUsers(searchConditionUsers, fetchParameters.pagination)
      )) as FilteredUsers[];

      let hiddenCount = 0;

      // Filter out users that are in the HIDDEN_USERS_IDS array
      loadedItems = loadedItems.filter((item) => {
        if (!HIDDEN_USER_IDS.includes(item.UserID)) {
          hiddenCount++;
          return true;
        }
        return false;
      });

      let newStatus: Status = 'loadedAll';

      if (loadedItems.length + hiddenCount > MARKETPLACE_ITEMS_PER_PAGE) {
        // There are still more items
        newStatus = 'hasMore';
        // Set only first {limit} items to context
        loadedItems = loadedItems.slice(0, MARKETPLACE_ITEMS_PER_PAGE);
      }

      if (clearExisting) {
        // Replace current items
        setFilteredUsers(loadedItems);
      } else {
        // Append loaded items
        setFilteredUsers([...filteredUsers, ...loadedItems]);
      }
      setUserStatus(newStatus);
    } catch (err) {
      captureException(err);
      setUserStatus('hasError');
    }
  };

  const loadCreations = async (clearExisting = false): Promise<void> => {
    if (NFTStatus === 'loadingMore') {
      return;
    }
    setNFTStatus(clearExisting ? 'initializing' : 'loadingMore');

    try {
      // Call API without pagination
      const fetchParameters = {
        searchConditionNFTs,
        pagination: {
          limit: MARKETPLACE_ITEMS_PER_PAGE + 1, // Request 1 more item to check if there are more items
          offset: clearExisting ? 0 : creations.length,
        },
      };
      let loadedItems = await getCreationsCache(
        JSON.stringify(fetchParameters),
        () => fetchCreations(searchConditionNFTs, fetchParameters.pagination)
      );

      let newStatus: Status = 'loadedAll';

      if (loadedItems.length > MARKETPLACE_ITEMS_PER_PAGE) {
        // There are still more items
        newStatus = 'hasMore';
        // Set only first {limit} items to context
        loadedItems = loadedItems.slice(0, MARKETPLACE_ITEMS_PER_PAGE);
      }

      if (clearExisting) {
        // Replace current items
        setCreations(loadedItems);
      } else {
        // Append loaded items
        setCreations([...creations, ...loadedItems]);
      }

      setNFTStatus(newStatus);
    } catch (err) {
      captureException(err);
      setNFTStatus('hasError');
    }
  };

  const shuffleItems = <T,>(items: T[]) => {
    // Using Durstenfeld shuffle to randomize items
    for (let i = items.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [items[i], items[j]] = [items[j], items[i]];
    }
    return items;
  };

  useEffect(() => {
    // Reload creations on search conditions change
    if (isLoaded) {
      // Reset current items
      setCreations([]);
      // Scroll to top immediately to prevent loop of infinite scroll
      window.scroll({
        top: 0,
      });
      loadCreations(true);
    }
  }, [searchConditionNFTs, isLoaded]);

  useEffect(() => {
    // Reload users on search conditions change
    if (isLoaded) {
      // Reset current items
      setFilteredUsers([]);
      // Scroll to top immediately to prevent loop of infinite scroll
      window.scroll({
        top: 0,
      });
      loadUsers(true);
    }
  }, [searchConditionUsers, isLoaded]);

  // Random Creation Button Related Code
  const [pointer, setPointer] = useState(MARKETPLACE_ITEMS_PER_PAGE);
  const [totalNumCreations, setTotalNumCreations] = useState(0);
  const [randomizedCreationIDs, setRandomizedCreationIDs] = useState([]);

  useEffect(() => {
    if (showRandom) {
      setNFTStatus('initializing');
      generateRandomCreationIDs();
    } else {
      resetRandomCreationSettings();
    }
  }, [showRandom]);

  useEffect(() => {
    if (showRandom) {
      filterRandomCreations();
    }
  }, [searchConditionNFTs]);

  useEffect(() => {
    if (showRandom) {
      loadRandomCreations();
    }
  }, [randomizedCreationIDs]);

  const generateRandomCreationIDs = async () => {
    const totalNumCreations = await fetchNumCreations(searchConditionNFTs);
    setTotalNumCreations(totalNumCreations);
    const shuffledCreations = shuffleItems(
      Array.from(new Array(totalNumCreations), (_, i) => i)
    );
    setRandomizedCreationIDs(shuffledCreations);
  };

  const filterRandomCreations = async () => {
    // If there are no randomized creations, do not do anything
    if (randomizedCreationIDs.length === 0) {
      return;
    }
    const seenIds = randomizedCreationIDs.slice(0, pointer);
    try {
      const items = await fetchCreationsByCreationIDs(
        searchConditionNFTs,
        seenIds.toString()
      );
      setRandomCreations(items);
    } catch (err) {
      captureException(err);
      setNFTStatus('hasError');
    }
    return;
  };

  const loadRandomCreations = async () => {
    if (NFTStatus === 'loadingMore') {
      return;
    }

    const randomCreationIDs = randomizedCreationIDs
      .slice(pointer - MARKETPLACE_ITEMS_PER_PAGE, pointer)
      .join(',');

    try {
      // Calling shuffle again after the fetch, otherwise the items will return
      // in ascending order which makes the results seem less random
      const creationItems = shuffleItems(
        await fetchCreationsByCreationIDs(
          searchConditionNFTs,
          randomCreationIDs
        )
      );
      const newStatus: Status =
        pointer < totalNumCreations ? 'hasMore' : 'loadedAll';
      setRandomCreations([...randomCreations, ...creationItems]);
      setPointer((currentPointer) => {
        return currentPointer + MARKETPLACE_ITEMS_PER_PAGE;
      });
      setNFTStatus(newStatus);
    } catch (err) {
      captureException(err);
      setNFTStatus('hasError');
    }
  };

  const resetRandomCreationSettings = () => {
    setPointer(MARKETPLACE_ITEMS_PER_PAGE);
    setRandomCreations([]);
  };

  // End Random Creation

  return (
    <MarketplaceItemsContext.Provider
      value={{
        initialSearchConditionNFTs,
        initialSearchConditionUsers,
        creations,
        setCreations,
        randomCreations,
        setRandomCreations,
        NFTStatus,
        setNFTStatus,
        userStatus,
        setUserStatus,
        searchConditionNFTs,
        setSearchConditionNFTs,
        loadCreations,
        loadRandomCreations,
        searchConditionUsers,
        setSearchConditionUsers,
        filteredUsers,
        setFilteredUsers,
        loadUsers,
        isLoaded,
        setIsLoaded,
        showRandom,
        setShowRandom,
      }}
    >
      {children}
    </MarketplaceItemsContext.Provider>
  );
};

export default MarketplaceItemsContext;
