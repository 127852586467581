export type MarketPlaceV2PrimarySale = {
  askHash: string;
  totalTokenAmount: number;
  availableTokenAmount: number;
  priceTokenTicker: 'ETH';
  currentETHPrice: number;

  // Ask Order
  askOrder: {
    askType: 0 | 1;
    signer: string;
    currency: 'ETH'; // or 0x000000...?
    amount: number;
    price: number;
    nonce: number;
    royalty: {
      recipients: string[];
      royalties: number[];
    };
    v: number;
    r: string;
    s: string;
  };

  // Commission
  commission: {
    askHash: string;
    amount: number;
    v: number;
    r: string;
    s: string;
  };

  // Token metadata
  tokenMetadata: {
    name: string;
    creatorName: string;
    description: string;
    mediaUri: string;
    v: number;
    r: string;
    s: string;
  };
};

// AskStatus
export const AskStatus = {
  Ongoing: 1,
  Finished: 2,
  Cancelled: 3,
} as const;

export type AskStatus = typeof AskStatus[keyof typeof AskStatus];
