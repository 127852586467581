import { useTranslation } from 'next-i18next';
import { useContext, useEffect } from 'react';

import { postAccessToken } from '@/apis/aniftyBackend/accessToken/postAccessToken';
import client from '@/apis/aniftyBackend/client';
import discordClient from '@/apis/discord/discordClient';
import getAccessTokenCookie from '@/apis/cookies/accessToken/getAccessToken';
import setAccessTokenCookie from '@/apis/cookies/accessToken/setAccessToken';
import AccessTokenContext from '@/contexts/AccessTokenContext';
import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';
import useCurrentUser from '@/hooks/useCurrentUser';
import { addBreadcrumb, captureException } from '@/utils/Sentry';

type Returns = {
  loading: boolean;
  accessToken: string | null;
  reload: () => void;
  requestSignature: () => Promise<boolean>;
};

const useAccessToken = (): Returns => {
  const { t } = useTranslation();

  const web3Context = useContext(Web3Context);
  const accessTokenContext = useContext(AccessTokenContext);

  const { user, reload } = useCurrentUser();

  /**
   * Load access token
   */
  const loadAccessTokenFromCookie = () => {
    const accessTokenFromCookie = getAccessTokenCookie();

    accessTokenContext.setAccessToken(accessTokenFromCookie);

    if (accessTokenFromCookie) {
      addBreadcrumb({
        category: 'auth',
        message: 'accessToken is loaded from cookie',
        level: 'info',
      });
    }
  };

  // load access token from cookie on mount
  useEffect(() => {
    loadAccessTokenFromCookie();
  }, []);

  /**
   * Prompt signature request
   *
   * returns true when authorized, false when failed to sign in
   */
  const requestSignature = async () => {
    // reload nonce before creating new signature
    await reload();

    if (!web3Context.web3.initialized) {
      // We cannot open signature prompt before initialization
      return;
    }

    const signer = web3Context.web3.web3Provider.getSigner();

    if (!user) {
      alert(t('useAccessToken.userNotLoadedError'));
      return false;
    }

    const message = `Welcome to ANIFTY!

Click "Sign" to sign in. No password needed!

Wallet address:
${user.userID}

Nonce:
${user.nonce}`;

    try {
      const signature = await signer.signMessage(message);

      const { accessToken } = await postAccessToken({
        message,
        signature,
      });

      // store token in cookie
      setAccessTokenCookie(accessToken);

      // Set Authorization header
      client.defaults.headers.common['Authorization'] = accessToken
        ? `Bearer ${accessToken}`
        : undefined;

      discordClient.defaults.headers.common['Authorization'] = accessToken
        ? `Bearer ${accessToken}`
        : undefined;

      // set token in state
      accessTokenContext.setAccessToken(accessToken);

      return true;
    } catch (err) {
      // code 4001 is user denial, so don't send error report
      if ('code' in err && err.code !== 4001) {
        captureException(err);
      }
      return false;
    }
  };

  return {
    loading: typeof accessTokenContext.accessToken === 'undefined',
    accessToken: accessTokenContext.accessToken || null,
    reload: loadAccessTokenFromCookie,
    requestSignature,
  };
};

export default useAccessToken;
