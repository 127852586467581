export default [
  {
    inputs: [
      { internalType: 'address[]', name: '_supportTokens', type: 'address[]' },
      { internalType: 'address', name: '_AniftyERC1155', type: 'address' },
      {
        internalType: 'address payable',
        name: '_commissionWallet',
        type: 'address',
      },
      { internalType: 'address', name: '_operator', type: 'address' },
      { internalType: 'uint256', name: '_maxRoyalty', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        components: [
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Offer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'OfferCancel',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
    ],
    name: 'OfferClaim',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountFilled',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'PrimaryBuy',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
    ],
    name: 'PrimaryCancel',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'bidHash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Bid',
        name: 'bidOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'string', name: 'uri', type: 'string' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Token',
        name: 'token',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'PrimaryClaim',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        indexed: false,
        internalType: 'address',
        name: 'buyer',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountFilled',
        type: 'uint256',
      },
    ],
    name: 'SecondaryBuy',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
    ],
    name: 'SecondaryCancel',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'bidHash',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Bid',
        name: 'bidOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address payable[]',
            name: 'recipients',
            type: 'address[]',
          },
          { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.SecondaryRoyalty',
        name: 'secondaryRoyalty',
        type: 'tuple',
      },
    ],
    name: 'SecondaryClaim',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'prevHash',
        type: 'bytes32',
      },
      { indexed: true, internalType: 'bytes32', name: 'hash', type: 'bytes32' },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.MintAsk',
        name: 'prevAskOrder',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        indexed: false,
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amountFilled',
        type: 'uint256',
      },
    ],
    name: 'Update',
    type: 'event',
  },
  {
    inputs: [],
    name: 'AniftyERC1155',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'admin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'amountFilled',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.MintAsk',
        name: 'prevAskOrder',
        type: 'tuple',
      },
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
    ],
    name: 'askUpdate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'commissionWallet',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'isCancelled',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'enum AniftyMarketplace.PauseType',
        name: '',
        type: 'uint8',
      },
    ],
    name: 'isPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxRoyalty',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'mintedHash',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Offer',
        name: 'offer',
        type: 'tuple',
      },
    ],
    name: 'offerCancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Offer',
        name: 'offer',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address payable[]',
            name: 'recipients',
            type: 'address[]',
          },
          { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.SecondaryRoyalty',
        name: 'secondaryRoyalty',
        type: 'tuple',
      },
    ],
    name: 'offerClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155BatchReceived',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'bytes', name: '', type: 'bytes' },
    ],
    name: 'onERC1155Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operator',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'owners',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'string', name: 'uri', type: 'string' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Token',
        name: 'token',
        type: 'tuple',
      },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'primaryBuy',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
    ],
    name: 'primaryCancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          {
            components: [
              {
                internalType: 'address payable[]',
                name: 'recipients',
                type: 'address[]',
              },
              { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
            ],
            internalType: 'struct Orders.Royalty',
            name: 'royalty',
            type: 'tuple',
          },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.MintAsk',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Bid',
        name: 'bidOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'string', name: 'uri', type: 'string' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Token',
        name: 'token',
        type: 'tuple',
      },
    ],
    name: 'primaryClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address payable[]',
            name: 'recipients',
            type: 'address[]',
          },
          { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.SecondaryRoyalty',
        name: 'secondaryRoyalty',
        type: 'tuple',
      },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'secondaryBuy',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
    ],
    name: 'secondaryCancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Orders.AskType',
            name: 'askType',
            type: 'uint8',
          },
          { internalType: 'address payable', name: 'signer', type: 'address' },
          { internalType: 'address', name: 'currency', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint256', name: 'nonce', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Ask',
        name: 'askOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'address', name: 'signer', type: 'address' },
          { internalType: 'uint256', name: 'price', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Bid',
        name: 'bidOrder',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'bytes32', name: 'askHash', type: 'bytes32' },
          { internalType: 'uint16', name: 'amount', type: 'uint16' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.Commission',
        name: 'commission',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'owner', type: 'address' },
          {
            internalType: 'address payable[]',
            name: 'recipients',
            type: 'address[]',
          },
          { internalType: 'uint16[]', name: 'royalties', type: 'uint16[]' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Orders.SecondaryRoyalty',
        name: 'secondaryRoyalty',
        type: 'tuple',
      },
    ],
    name: 'secondaryClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address payable',
        name: '_commissionWallet',
        type: 'address',
      },
    ],
    name: 'setCommissionWallet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_maxRoyalty', type: 'uint256' }],
    name: 'setMaxRoyalty',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_operator', type: 'address' }],
    name: 'setOperator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: '_owners', type: 'address[]' },
      { internalType: 'bool', name: '_set', type: 'bool' },
    ],
    name: 'setOwners',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'enum AniftyMarketplace.PauseType[]',
        name: '_pauseTypes',
        type: 'uint8[]',
      },
      { internalType: 'bool', name: '_set', type: 'bool' },
    ],
    name: 'setPause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address[]', name: '_supportTokens', type: 'address[]' },
      { internalType: 'bool', name: '_set', type: 'bool' },
    ],
    name: 'setSupportedTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'supportedTokens',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_admin', type: 'address' }],
    name: 'transferAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
