import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { MouseEventHandler, VFC } from 'react';

import routes from '@/config/routes';
import useGetStaticSiteUrl from '@/hooks/useGetStaticSiteUrl';
import useGetBlogSiteUrl from '@/hooks/useGetBlogSiteUrl';
import DiscordIcon from '@/icons/discord.svg';
import MediumIcon from '@/icons/medium.svg';
import TelegramIcon from '@/icons/telegram.svg';
import TwitterIcon from '@/icons/twitter.svg';
import {
  getUniswapExchangeLinkWETH,
  getUniswapExchangeLink,
} from '@/utils/uniswap';

import styles from './NavbarDropdown.module.scss';

type Props = {
  myPageHandler: MouseEventHandler;
  closeDropdownHandler: MouseEventHandler;
};

const NavbarDropdown: VFC<Props> = ({
  myPageHandler,
  closeDropdownHandler,
}) => {
  const { t } = useTranslation();
  const getStaticSite = useGetStaticSiteUrl();
  const getBlogSite = useGetBlogSiteUrl();

  return (
    <>
      <button onClick={closeDropdownHandler}>
        <div className={styles.navBg}></div>
      </button>
      <nav className={styles.nav}>
        <div>
          <div className={styles.navList}>
            <Link href={routes.marketplace}>
              <a className={styles.activeLink} onClick={closeDropdownHandler}>
                {t('header.marketplace')}
              </a>
            </Link>
            <a href={getStaticSite('about')}>{t('header.aboutUs')}</a>
            <a href={getStaticSite('purchase')}>{t('header.purchase')}</a>
            <a href={getStaticSite('apply')}>{t('header.sell')}</a>
            <a href={getStaticSite('entry')}>
              {t('header.creatorApplication')}
            </a>
            <a href={getStaticSite('faq')}>{t('header.faq')}</a>
            <a href={getStaticSite('info')}>{t('header.latestNews')}</a>
            <a href={getBlogSite('')} target='_blank'>{t('header.blog')}</a>
            <a className={`${styles.btn}`} onClick={myPageHandler}>
              {t('header.myPage')}
            </a>
          </div>
          <div className={`${styles.under}`}>
            <Link href={routes.auctionActivities}>
              <a className={styles.myAuctions} onClick={closeDropdownHandler}>
                {t('header.myAuctions')}
              </a>
            </Link>
            <a href={getUniswapExchangeLink()} target="_blank" rel="noreferrer">
              {t('header.swapETH')}
            </a>
            <a
              href={getUniswapExchangeLinkWETH()}
              target="_blank"
              rel="noreferrer"
            >
              {t('header.swapWETH')}
            </a>
          </div>
        </div>
        <div className={styles.snsList}>
          <a
            href="https://twitter.com/anifty_jp"
            target="_blank"
            className="footerSnsList__item"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://discord.com/invite/jHHmxv5Xnb"
            target="_blank"
            className="footerSnsList__item"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://t.me/joinchat/gOJFYPx57rI5MjA1"
            target="_blank"
            className="footerSnsList__item"
            rel="noreferrer"
          >
            <TelegramIcon />
          </a>
          <a
            href="https://anifty-jp.medium.com/"
            target="_blank"
            className="footerSnsList__item"
            rel="noreferrer"
          >
            <MediumIcon />
          </a>
        </div>
      </nav>
    </>
  );
};

export default NavbarDropdown;
