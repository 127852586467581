import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useRef, useState, VFC } from 'react';

import { useUnreadNotificationCount } from '@/apis/aniftyBackend/notifications/getUnreadNotificationCount';
import HeaderItemBadge from '@/components/Header/HeaderItemBadge';
import routes from '@/config/routes';
import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';
import { useActivityCount } from '@/hooks/useActivityCount';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import AlertBell from '@/icons/alert-bell.svg';
import AniftyIcon from '@/icons/anifty-logo.svg';

import styles from './Header.module.scss';
import NavbarDropdown from './NavbarDropdown';

const Header: VFC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const web3Context = useContext(Web3Context);
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
  const languageSelectorRef = useRef(null);

  // new
  const [showDropdown, setShowDropdown] = useState(false);
  const [offset, setOffset] = useState(0);
  const offsetLimit = 96;

  // handle language selector
  const handleLanguageSelector = () => {
    setShowDropdown(false);
    setIsLanguageSelectorOpen(!isLanguageSelectorOpen);
  };

  // close language selector on outside click
  useOnClickOutside(languageSelectorRef, () =>
    setIsLanguageSelectorOpen(false)
  );

  // Keep track of Y position to change header transparency
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // Prevent scroll when nav dropdown is open
  useEffect(() => {
    if (showDropdown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showDropdown]);

  const localeList = useMemo(
    () => [
      {
        key: 'ja',
        label: '日本語',
        selected: router.locale === 'ja',
      },
      {
        key: 'en',
        label: 'English',
        selected: router.locale === 'en',
      },
      {
        key: 'zh',
        label: '中文(简体)',
        selected: router.locale === 'zh',
      },
      {
        key: 'zh-Hant',
        label: '中文(繁體)',
        selected: router.locale === 'zh-Hant',
      },
    ],
    [router.locale]
  );

  const currentLocale = useMemo(
    () => localeList.find((locale) => locale.selected),
    [localeList]
  );

  const isSignedIn =
    web3Context.currentAccountAddress?.length !== 0 &&
    web3Context.currentAccountAddress !== null;

  const handleSignInButtonClick = async () => {
    setShowDropdown(false);
    if (isSignedIn) {
      // navigate to my page
      await router.push(routes.myPage(web3Context.currentAccountAddress));
      return;
    } else {
      await router.push(routes.connectWallet);
    }
  };

  const closeDropdownHandler = () => {
    setShowDropdown(false);
  };

  const { data: unreadNotificationCount } = useUnreadNotificationCount();
  const { activityCount } = useActivityCount();

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerInner}>
          <Link href={routes.home}>
            <a onClick={() => setShowDropdown(false)}>
              <AniftyIcon />
            </a>
          </Link>
          <div className={styles.headerLang}>
            <button
              className={styles.btnReset}
              onClick={handleLanguageSelector}
            >
              <div className={styles.headerLangLabel}>
                {currentLocale.label}
              </div>
            </button>
            <div
              className={`${styles.headerLang} ${styles.headerLangList} ${
                isLanguageSelectorOpen && styles.localeListActive
              }`}
              ref={languageSelectorRef}
            >
              {localeList.map((locale) => (
                <li key={locale.key}>
                  <Link
                    // Next.js warns if hash does not match on server/client
                    // so remove it here
                    href={router.asPath.split('#')[0]}
                    locale={locale.key}
                  >
                    <a className={locale.selected && styles.localeListActive}>
                      {locale.label}
                    </a>
                  </Link>
                </li>
              ))}
            </div>
          </div>
          <nav className={styles.headerNav}>
            <Link href={routes.marketplace}>
              <a className={styles.activeLink} onClick={closeDropdownHandler}>
                {t('header.marketplace')}
              </a>
            </Link>
            <Link href={routes.auctionActivities}>
              <a className={styles.activeLink} onClick={closeDropdownHandler}>
                <HeaderItemBadge count={activityCount} />
                {t('header.myAuctions')}
              </a>
            </Link>
            <Link href={routes.notifications}>
              <a className={styles.bell} onClick={closeDropdownHandler}>
                <AlertBell className={styles.alertBell} />
                <HeaderItemBadge count={unreadNotificationCount} />
              </a>
            </Link>
            <button
              className={styles.btnReset}
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              <div
                className={
                  showDropdown
                    ? styles.headNavTriggerActive
                    : styles.headerNavTrigger
                }
              >
                <span></span>
              </div>
            </button>
            <a
              className={`${styles.btn} ${styles.activeLink}`}
              onClick={handleSignInButtonClick}
            >
              {t('header.myPage')}
            </a>
          </nav>
        </div>
      </header>
      {showDropdown && (
        <>
          <NavbarDropdown
            myPageHandler={handleSignInButtonClick}
            closeDropdownHandler={closeDropdownHandler}
          />
        </>
      )}
    </>
  );
};

export default Header;
