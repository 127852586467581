import { ListingType } from '../../apis/aniftyBackend/mintAsks/interface';

export type MarketplaceCreationStatus =
  | 'all'
  | 'available'
  | 'sold'
  | 'reserved'
  | 'auction';

export type MarketplaceUserStatus = 'artist' | 'collector';

export type UserType = 'all' | 'artist' | 'collector';

export const SortParam = {
  ListedAtDesc: '-listedAt',
  ListedAtAsc: '+listedAt',
  CreatedAtDesc: '-createdAt',
  CreatedAtAsc: '+createdAt',
  PriceDesc: '-price',
  PriceAsc: '+price',
} as const;

export const SortParamProfile = {
  profileCreationDateAsc: '+profile',
  profileCreationDateDesc: '-profile',
  salesAsc: '+sales',
  salesDesc: '-sales',
  creationCountAsc: '+creation',
  creationCountDesc: '-creation',
  collectionCountAsc: '+collection',
  collectionCountDesc: '-collection',
  // purchaseCountAsc: '+purchases',
  // purchaseCountDesc: '-purchases',
} as const;

export type SortParam = typeof SortParam[keyof typeof SortParam];
export type SortParamProfile =
  typeof SortParamProfile[keyof typeof SortParamProfile];

export type SearchConditionNFTs = {
  keyword: string | null;
  tagNames: string[] | null;
  tagNamesJA: string[] | null;
  sort: SortParam;
  priceMin: string | null;
  priceMax: string | null;
  status: MarketplaceCreationStatus;
};
export type SearchConditionUsers = {
  tagNames: string[] | null;
  tagNamesJA: string[] | null;
  sort: SortParamProfile;
  verification: UserType;
  keyword: string;
};
export type PatchCreationRequestAfterSale = {
  creationID: number;
  params: PatchCreationRequestAfterSaleParams;
};

export type PatchCreationRequestAfterSaleWithTags = {
  creationID: number;
  params: PatchCreationRequestAfterSaleParamsWithTags;
};

type PatchCreationRequestAfterSaleParams = {
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
};

type PatchCreationRequestAfterSaleParamsWithTags = {
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
  tags: number[];
  addedTags: number[];
  removedTags: number[];
};

export const MARKETPLACE_ITEMS_PER_PAGE = 12;

export type RandomCreationItem = {
  saleId: number;
  aniftyTokenID: number;
  creationID: number;
  tokenName: string;
  tokenNameJA: string | null;
  imageURL: string;
  totalTokenAmount: number | null;
  availableTokenAmount: number;
  price: string;
  creationTimestamp: number;
  creatorProfile: {
    userName: string;
    displayNameEN: string;
    displayNameJP: string | null;
    profilePicUrl: string | null;
  };
  width: number;
  height: number;
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
  reservedAt: string | null;
  listingType: ListingType;
  highestBidPrice: string | null;
  endTime: string | null;
};
