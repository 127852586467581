import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { BigNumber, Contract, ethers, providers } from 'ethers';

import AniftyERC1155Abi from '@/abis/AniftyERC1155V2Ipfs';
import { addresses } from '@/constants/addresses';

import { ExistingTokenURIType } from '../aniftyBackend/aniftyTokens/interface';

export const getAniftyERC1155V2IpfsContract = async (
  provider: providers.Provider | providers.JsonRpcSigner
): Promise<Contract> => {
  return new ethers.Contract(
    addresses.AniftyERC1155V2,
    AniftyERC1155Abi,
    provider
  );
};

// getters
export const getBalanceERC1155 = async (
  tokenContract: Contract,
  accountAddress: string,
  tokenId: BigNumber | string | number
): Promise<BigNumber> => {
  return await tokenContract.balanceOf(accountAddress, tokenId);
};

export const getBalanceBatchERC1155 = async (
  tokenContract: Contract,
  accountAddresses: string[],
  tokenIds: (BigNumber | string | number)[]
): Promise<BigNumber[]> => {
  return await tokenContract.balanceOfBatch(accountAddresses, tokenIds);
};

export const isApprovedForAllERC1155 = async (
  tokenContract: Contract,
  accountAddress: string,
  operatorAddress: string
): Promise<boolean> => {
  return await tokenContract.isApprovedForAll(accountAddress, operatorAddress);
};

export const isWhitelisted = async (
  tokenContract: Contract,
  accountAddresses: string[]
): Promise<boolean[]> => {
  return await tokenContract.whitelist(accountAddresses);
};

export const getTokenCreator = async (
  tokenContract: Contract,
  tokenId: BigNumber | string | number
): Promise<string> => {
  return await tokenContract.creators(tokenId);
};

// setters
export const setApprovalForAllERC1155 = async (
  tokenContract: Contract,
  operatorAddress: string,
  isOperator: boolean
): Promise<TransactionReceipt> => {
  const setApprovalForAllTx = await tokenContract.setApprovalForAll(
    operatorAddress,
    isOperator
  );
  return await setApprovalForAllTx.wait();
};

export const safeTransferFromDataERC1155 = async (
  tokenContract: Contract,
  senderAddress: string,
  recipientAddress: string,
  tokenId: BigNumber | string | number,
  tokenAmount: BigNumber | string | number,
  data = '0x'
): Promise<TransactionReceipt> => {
  const safeTransferFromDataTx = await tokenContract.safeTransferFrom(
    senderAddress,
    recipientAddress,
    tokenId,
    tokenAmount,
    data
  );
  return await safeTransferFromDataTx.wait();
};

export const safeTransferFromBatchERC1155 = async (
  tokenContract: Contract,
  senderAddress: string,
  recipientAddress: string,
  tokenIds: (BigNumber | string | number)[],
  tokenAmounts: (BigNumber | string | number)[],
  data = '0x'
): Promise<TransactionReceipt> => {
  const safeTransferFromDataTx = await tokenContract.safeBatchTransferFrom(
    senderAddress,
    recipientAddress,
    tokenIds,
    tokenAmounts,
    data
  );
  return await safeTransferFromDataTx.wait();
};

export const transferV1 = async (
  tokenContract: Contract,
  tokenIds: number[],
  amounts: number[],
  existingTokenURIs: ExistingTokenURIType[],
  data = '0x'
): Promise<TransactionReceipt> => {
  const transferV1Tx = await tokenContract.transferV1(
    tokenIds,
    amounts,
    existingTokenURIs,
    data
  );
  return await transferV1Tx.wait();
};
