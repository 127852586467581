import { useQuery } from 'react-query';

import client from '@/apis/aniftyBackend/client';
import { AskStatus } from '@/apis/aniftyBackend/marketplaceV2/interface';
import useAccessToken from '@/hooks/useAccessToken';
import useWalletAddress from '@/hooks/useWalletAddress';
import { QueryConfig } from '@/utils/react-query';

export type AskHashWithCreation = {
  askHash: string;
  status: AskStatus;
  reservePrice: string;
  creation: {
    creationID: number;
    imageURL: string;
    creationTitle: string;
    creationTitleJA: string | null;
    creatorName: string;
    creatorNameJA: string | null;
    creatorProfileImageURL: string | null;
  };
};

type GetMintAsksResponse = AskHashWithCreation[];

export const getMintAsks = async ({
  ignoreCancelledAsks = false,
}: {
  ignoreCancelledAsks?: boolean;
} = {}): Promise<GetMintAsksResponse> => {
  const response = await client.get<GetMintAsksResponse>(`api/mint_asks`, {
    params: {
      type: 'auction',
    },
  });

  if (ignoreCancelledAsks) {
    // filter out cancelled asks
    return response.data.filter((ask) => ask.status !== AskStatus.Cancelled);
  }

  return response.data;
};

type QueryFnType = typeof getMintAsks;

type UseMintAsksOptions = {
  ignoreCancelledAsks?: boolean;
  config?: QueryConfig<QueryFnType>;
};

export const useMintAsks = ({
  ignoreCancelledAsks = false,
  config,
}: UseMintAsksOptions = {}) => {
  const { currentAccountAddress } = useWalletAddress();
  const { accessToken } = useAccessToken();

  return useQuery({
    ...config,
    enabled: !!currentAccountAddress && !!accessToken,
    queryKey: ['mintAsks', currentAccountAddress, ignoreCancelledAsks],
    queryFn: () => getMintAsks({ ignoreCancelledAsks }),
  });
};
