import * as Sentry from '@sentry/nextjs';

/**
 * Captures an exception event and sends it to Sentry.
 *
 * @returns {string} The generated eventId.
 */
const captureException: typeof Sentry.captureException = (...args): string => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.error(...args);
  }
  return Sentry.captureException(...args);
};

export default captureException;
