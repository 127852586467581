import client from '@/apis/aniftyBackend/client';
// import { useQuery } from 'react-query';
// import { QueryConfig } from '@/utils/react-query';
import { SearchConditionNFTs } from '@/containers/MarketplacePage/interface';

export const fetchNumCreations = async ({
  keyword,
  tagNames,
  priceMin,
  priceMax,
  status,
}: SearchConditionNFTs): Promise<number> => {
    // `keyword` param
    const params = new URLSearchParams();
    if (keyword) {
      params.append('keyword', keyword);
    }
  
    // add `tag_name` param if `tagNames` specified
    if (tagNames && tagNames.length) {
      tagNames.forEach((tagName) => {
        params.append('tag_name[]', tagName.toString());
      });
    }
  
    // `price_min` param
    if (priceMin) {
      params.append('price_min', priceMin.toString());
    }
  
    // `price_max` param
    if (priceMax) {
      params.append('price_max', priceMax.toString());
    }
  
    // `status` param
    if (status && status !== 'all') {
      params.append('status', status);
    }
  const numCreations = await client.get(`api/creations/total_number`, {
    params
  });
  
  return numCreations.data.totalCreationCount;
};

// Commented out for now, think we can use it later?
// type QueryFnType = typeof fetchNumCreations;

// type UseNumCreationOptions = {
//   config?: QueryConfig<QueryFnType>;
// }

// export const useNumCreations = ({config}: UseNumCreationOptions) =>
//   useQuery({
//     ...config,
//     queryKey: ['numCreations'],
//     queryFn: () => fetchNumCreations(),
//   });
