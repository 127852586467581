import client from '@/apis/aniftyBackend/client';
import { FilteredUsers } from '@/apis/aniftyBackend/creations/interface';
import { SearchConditionUsers } from '@/containers/MarketplacePage/interface';
type PaginationProps = {
  limit: number;
  offset: number;
};

const fetchUsers = async (
  { tagNames, sort, verification, keyword }: SearchConditionUsers,
  pagination: PaginationProps = null
): Promise<FilteredUsers[]> => {
  //Set up map
  const varToLinks = new Map<string, string>();
  varToLinks.set('creation', 'api/creators/byUnits');
  varToLinks.set('sales', 'api/creators/bySales');
  varToLinks.set('profile', 'api/users/sortByCreationDate');
  varToLinks.set('collection', 'api/users/sortByCollectionCount');
  varToLinks.set('purchases', 'api/users/sortBySpentAmount');

  const params = new URLSearchParams();

  let getURL = '';
  // `sort` param
  if (sort) {
    getURL = varToLinks.get(sort.slice(1, sort.length));
    let order = 'desc';
    if (sort.indexOf('-') != -1) {
      order = 'asc';
    }
    params.append('order', order);
  }
  if (verification) {
    params.append('type', verification);
  }
  // `limit` & `offset` param
  params.append('keyword', keyword);
  if (pagination) {
    params.append('limit', pagination.limit.toString());
    params.append('offset', pagination.offset.toString());
  }

  // add `tag_name` param if `tagNames` specified
  if (tagNames && tagNames.length) {
    tagNames.forEach((tagName) => {
      params.append('tag_name[]', tagName.toString());
    });
  }

  const response = await client.get<FilteredUsers[]>(getURL, {
    params,
  });
  return response.data;
};

export default fetchUsers;
