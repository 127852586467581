import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { gaPageView } from '@/utils/gtag';

/**
 * Submit page view event to GA on every route change
 */

export const usePageView = (): void => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      gaPageView(path);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
