import { VFC } from 'react';

import styles from './HeaderItemBadge.module.scss';

type Props = {
  count: number;
};

const HeaderItemBadge: VFC<Props> = ({ count }: Props) => {
  if (!count) {
    return null;
  }

  return <span className={styles.headerItemBadge}>{count}</span>;
};

export default HeaderItemBadge;
