/* eslint-disable */
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import {
  ExternalProvider,
  JsonRpcFetchFunc,
} from '@ethersproject/providers/src.ts/web3-provider';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';

export const getWeb3Provider = (
  provider: ExternalProvider | JsonRpcFetchFunc | null | unknown
) => {
  if (provider) {
    return new ethers.providers.Web3Provider(provider);
  } else {
    return null;
  }
};

export const getMetamaskProvider = async (): Promise<unknown | null> => {
  const provider = await detectEthereumProvider();
  if (provider) {
    return provider;
  } else {
    return null;
  }
};

export const isMetamaskConnected = (provider: any): boolean => {
  return provider?.isConnected() || false;
};

export const connectToMetamask = async (web3Provider) => {
  await web3Provider.request({ method: 'eth_requestAccounts' });
};

export const addMetamaskListeners = (
  provider,
  chainChangedCallback,
  messageCallback,
  accountsChangedCallback
) => {
  provider.on('chainChanged', (chainId) => {
    chainChangedCallback(chainId);
  });
  provider.on('message', (message) => {
    messageCallback(message);
  });
  provider.on('accountsChanged', (accounts) => {
    accountsChangedCallback(accounts);
  });
};

export const getAccountSigner = async (web3Provider) => {
  return await web3Provider.getSigner();
};

export const weiToEth = (weiBalance: BigNumberish): string => {
  return ethers.utils.formatEther(weiBalance);
};

export const ethToWei = (ethBalance: string): BigNumber => {
  return ethers.utils.parseEther(ethBalance);
};

export const formatDecimals = (
  numberString: string,
  decimals: number
): string => {
  const decimalPos = numberString.indexOf('.');
  if (decimalPos === -1) {
    return numberString;
  } else {
    return numberString.slice(0, decimalPos + 1 + decimals);
  }
};

export const formatUnits = (
  weiBalance: BigNumberish,
  decimals: string | BigNumberish
) => {
  return ethers.utils.formatUnits(weiBalance, decimals);
};

export const isAddress = (address: string): boolean => {
  return ethers.utils.isAddress(address);
};
