const network = process.env.NEXT_PUBLIC_ETH_NETWORK;
const weth = process.env.NEXT_PUBLIC_WETH_CONTRACT;

if (!network || !network.length) {
  throw new Error(
    'Failed to get network name. Set `NEXT_PUBLIC_ETH_NETWORK` env var.'
  );
}

const chain = network === 'Goerli' ? 'goerli' : 'mainnet';

export const getUniswapExchangeLink = (): string =>
  `https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=${weth}&chain=${chain}`;

  export const getUniswapExchangeLinkWETH = (): string =>
  `https://app.uniswap.org/#/swap?inputCurrency=${weth}&outputCurrency=eth&chain=${chain}`;
