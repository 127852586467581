import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';

import client from '@/apis/aniftyBackend/client';
import destroyAccessToken from '@/apis/cookies/accessToken/destroyAccessToken';
import getAccessToken from '@/apis/cookies/accessToken/getAccessToken';
import getAccessTokenPayload from '@/apis/cookies/accessToken/getAccessTokenPayload';
import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';
import discordClient from '@/apis/discord/discordClient';

const contextDefaultValue = {
  accessToken: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAccessToken: () => {},
};

const AccessTokenContext =
  createContext<{
    accessToken: undefined | string | null;
    setAccessToken: (accessToken: string | null) => void;
  }>(contextDefaultValue);

/**
 * AccessTokenProvider
 */

type ProviderProps = {
  children: ReactNode;
};

export const AccessTokenProvider: VFC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [accessToken, setAccessTokenState] = useState(undefined);
  const web3Context = useContext(Web3Context);

  // clear access token cookie on account switch
  useEffect(() => {
    const { currentAccountAddress } = web3Context;
    const accessTokenPayload = getAccessTokenPayload();
    if (
      currentAccountAddress &&
      accessTokenPayload?.userId &&
      currentAccountAddress.toLowerCase() !==
        accessTokenPayload.userId.toLowerCase()
    ) {
      // public address has changed -> clear cookie
      destroyAccessToken();
    }
  }, [web3Context.currentAccountAddress]);

  // set auth header on every access token update
  useEffect(() => {
    client.defaults.headers.common['Authorization'] = accessToken
      ? `Bearer ${accessToken}`
      : undefined;
    discordClient.defaults.headers.common['Authorization'] = accessToken
      ? `Bearer ${accessToken}`
      : undefined;
  }, [accessToken]);

  const setAccessToken = (_accessToken) => {
    setAccessTokenState(_accessToken);
  };

  // Check if current token has expired
  const checkAccessTokenExpiry = () => {
    const latestAccessToken = getAccessToken();
    if (accessToken && !latestAccessToken) {
      setAccessToken(latestAccessToken);
    }
  };

  // Run expiry checker every second
  useEffect(() => {
    const intervalID = setInterval(() => {
      checkAccessTokenExpiry();
    }, 1000);
    return () => clearInterval(intervalID);
  }, [accessToken]);

  return (
    <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenContext;
