import { GetStaticProps } from 'next';
import { appWithTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import 'tippy.js/dist/tippy.css';
import '@/styles/global.scss';
import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { QueryClientProvider } from 'react-query';

import Footer from '@/components/Footer';
import { GATrackingTag } from '@/components/GATrackingTag';
import Header from '@/components/Header';
import { AccessTokenProvider } from '@/contexts/AccessTokenContext';
import { CurrentUserProvider } from '@/contexts/CurrentUserContext';
import { MarketPlaceItemsProvider } from '@/contexts/MarketplaceItemsContext';
import ProfileProvider from '@/contexts/ProfileProvider';
import Web3Provider from '@/contexts/Web3Provider/Web3Provider';
import { queryClient } from '@/utils/react-query';

const MyApp = ({ Component, pageProps: _pageProps }) => {
  const { hideFooter, ...pageProps } = _pageProps;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ReactModal.setAppElement('#__next');
    }
  }, []);

  return (
    <Web3Provider>
      <AccessTokenProvider>
        <QueryClientProvider client={queryClient}>
          <ProfileProvider>
            <CurrentUserProvider>
              <MarketPlaceItemsProvider>
                <GATrackingTag />
                <Head>
                  <title>ANIFTY</title>
                  <link rel="preconnect" href="https://fonts.gstatic.com" />
                  <link
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
                    rel="stylesheet"
                  />
                  <link
                    href="https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp_s.min.css"
                    rel="stylesheet"
                  />
                  <link
                    href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&subset=japanese"
                    rel="stylesheet"
                  />
                  {/* brand icons */}
                  <link rel="icon" href="/images/favicon.svg" />
                  <link
                    rel="apple-touch-icon"
                    href="/images/apple-touch-icon.png"
                  />
                  {/* OGP fallback */}
                  <meta
                    property="description"
                    key="description"
                    content="The world's first NFT Marketplace focused on anime characters"
                  />
                  <meta property="og:title" key="og:title" content="ANIFTY" />
                  <meta property="og:type" key="og:type" content="website" />
                  <meta
                    property="og:description"
                    key="og:description"
                    content="The world's first NFT Marketplace focused on anime characters"
                  />
                  <meta
                    property="og:image"
                    key="og:image"
                    content="https://anifty.jp/images/anifty-ogp.png"
                  />
                  <meta
                    property="og:image:alt"
                    key="og:image:alt"
                    content="ANIFTY"
                  />
                  <meta
                    property="og:url"
                    key="og:url"
                    content="https://anifty.jp"
                  />
                  <meta
                    property="og:site_name"
                    key="og:site_name"
                    content="ANIFTY"
                  />
                  {/* twitter specific */}
                  <meta
                    name="twitter:card"
                    key="twitter:card"
                    content="summary_large_image"
                  />
                  <meta
                    name="twitter:site"
                    key="twitter:site"
                    content="@anifty_jp"
                  />
                </Head>
                <Header />
                <div className="page-content">
                  <Component {...pageProps} />
                </div>
                {!hideFooter && <Footer />}
              </MarketPlaceItemsProvider>
            </CurrentUserProvider>
          </ProfileProvider>
        </QueryClientProvider>
      </AccessTokenProvider>
    </Web3Provider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
});

export default appWithTranslation(MyApp);
