import { captureException } from '@/utils/Sentry';
import { useState } from 'react';

export const useCurrentPageCache = () => {
  const [cacheContent, setCacheContent] = useState({});

  const invalidate = () => {
    setCacheContent({});
  };

  const getCacheContent = async (cacheKey: string, fetchFunction) => {
    if (cacheContent[cacheKey]) {
      return cacheContent[cacheKey];
    } else {
      try {
        const data = await fetchFunction();
        setCacheContent({
          ...cacheContent,
          [cacheKey]: data,
        });
        return data;
      } catch (error) {
        captureException(error);
        return null;
      }
    }
  };

  return {
    getCacheContent,
    invalidate,
  };
};
