/**
 * Hidden User ID List
 *
 * Accounts on this list will be hidden from the marketplace and user pages.
 */

 const HIDDEN_USER_IDS = [
    "0xa2710f48411e25f4d0a6d903aa14026814449284", // punpunpanda_2
    "0x6343ddfbcc1ecf83ed4990365e1f5f17e7d8f5b5", //ringo_pie
    "0x749b996904415a649158051270892cd4df8ad8df", // CRTEST
    "0x33b29add32bb1a0a18f49e0c53ecc85bc3689945", // timAnifty
    "0xbe5312a834728e23430eabb077d08798374ef541", // Tim-ANIFTY
    "0xe68642af41461528bab9668579a6c16015d8af66", // TEAM ANIFTY
    "0x9010d6b75f0c77adf1b66d0f308991f09170b906", // punpunpanda
  ];
  
  export default HIDDEN_USER_IDS;
  