export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

export const GA4_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID;

// Measure PageView (to be called on route change)
export const gaPageView = (path: string): void => {
  if (!('gtag' in window)) {
    console.error(
      `Failed to submit page view (${path}) because gtag is not ready`
    );
    return;
  }

  window.gtag('config', GA_TRACKING_ID, {
    page_path: path,
  });
};

// Submit Event
export const gaEvent = ({
  action,
  category,
  label,
  value = '',
}: {
  action: string;
  category: string;
  label: string | Record<string, string | number>;
  value?: string;
}): void => {
  if (!('gtag' in window)) {
    console.error(
      `Failed to submit event (${action}) because gtag is not ready`
    );
    return;
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  });
};

export const gaSetUser = (userID: string): void => {
  if (!('gtag' in window)) {
    console.error(
      `Failed to set user ID (${userID}) because gtag is not ready`
    );
    return;
  }

  window.gtag('set', { user_id: userID });
};

// Set `User Type` custom dimension
export const gaSetUserType = (userType: 'collector' | 'creator'): void => {
  if (!('gtag' in window)) {
    console.error(
      `Failed to set user type (${userType}) because gtag is not ready`
    );
    return;
  }

  // Maps 'dimension1' to 'user_type'.
  window.gtag('config', GA_TRACKING_ID, {
    custom_map: { dimension1: 'user_type' },
  });

  // Sends an event that passes 'userType' as a parameter.
  window.gtag('event', 'user_type_dimension', { user_type: userType });
};
