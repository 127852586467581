import { BigNumber, Contract, ethers, providers, Transaction } from 'ethers';

import AniftySaleAbi from '@/abis/AniftySale';
import {
  ArtistInfoData,
  ArtistRoyalties,
  ArtistRoyaltyData,
  Auction,
  PrimarySale,
  PrimarySaleData,
  Sale,
  SaleData,
} from '@/apis/blockchain/interface';
import { addresses } from '@/constants/addresses';

export const getAniftySaleContract = async (
  provider: providers.Provider | providers.JsonRpcSigner
): Promise<Contract> => {
  return new ethers.Contract(
    addresses.AniftyMarketplaceV1,
    AniftySaleAbi,
    provider
  );
};

// getters
export const getDiscount = async (
  saleContract: Contract
): Promise<BigNumber> => {
  return await saleContract.discount();
};

export const getCommissionFee = async (
  saleContract: Contract
): Promise<BigNumber> => {
  return await saleContract.commissionFee();
};

export const getCommissionWallet = async (
  saleContract: Contract
): Promise<string> => {
  return await saleContract.commissionWallet();
};

export const getAdmin = async (saleContract: Contract): Promise<string> => {
  return await saleContract.admin();
};

export const getSale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number
): Promise<Sale> => {
  return await saleContract.sales(saleId);
};

export const getPrimarySale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number
): Promise<PrimarySale> => {
  return await saleContract.primarySales(saleId);
};

export const getArtistInfo = async (
  saleContract: Contract,
  artistAddress: string
): Promise<ArtistInfoData> => {
  return await saleContract.artistInfo(artistAddress);
};

export const getArtistRoyalties = async (
  saleContract: Contract,
  tokenId: BigNumber | string | number
): Promise<ArtistRoyaltyData> => {
  return await saleContract.artistRoyalties(tokenId);
};

export const getSupportedTokens = async (
  saleContract: Contract,
  tokenId: string
): Promise<boolean> => {
  return await saleContract.supportedTokens(tokenId);
};

export const getAuction = async (
  saleContract: Contract,
  auctionId: BigNumber | string | number
): Promise<Auction> => {
  return await saleContract.auctions(auctionId);
};

export const getArtistRoyalty = async (
  saleContract: Contract,
  tokenId: BigNumber | string | number
): Promise<ArtistRoyalties> => {
  return await saleContract.artistRoyalties(tokenId);
};

export const getName = async (saleContract: Contract): Promise<string> => {
  return await saleContract.name();
};

export const getSalesInfo = async (
  saleContract: Contract,
  saleIds: (BigNumber | string | number)[]
): Promise<SaleData> => {
  return await saleContract.getSalesInfo(saleIds);
};

export const getPrimarySalesInfo = async (
  saleContract: Contract,
  saleIds: (BigNumber | string | number)[]
): Promise<PrimarySaleData> => {
  return await saleContract.getPrimarySalesInfo(saleIds);
};

// setters
export const mintBatchAndList = async (
  saleContract: Contract,
  _creationIds: (BigNumber | string | number)[],
  _amounts: (BigNumber | string | number)[],
  _prices: (BigNumber | string | number)[],
  _tokens: string[],
  _artists: string[],
  _names: string[],
  _creatorNames: string[],
  _descriptions: string[],
  _mediaUris: string[]
): Promise<Transaction> => {
  const mintBatchAndListTx = await saleContract.mintBatchAndList(
    _creationIds,
    _amounts,
    _prices,
    _tokens,
    _artists,
    _names,
    _creatorNames,
    _descriptions,
    _mediaUris
  );
  return mintBatchAndListTx;
};

export const mintAndList = async (
  saleContract: Contract,
  _creationId: BigNumber | string | number,
  _amount: BigNumber | string | number,
  _price: BigNumber | string | number,
  _token: string,
  _artist: string,
  _name: string,
  _creatorName: string,
  _description: string,
  _mediaUri: string
): Promise<Transaction> => {
  const mintAndListTx = await saleContract.mintAndList(
    _creationId,
    _amount,
    _price,
    _token,
    _artist,
    _name,
    _creatorName,
    _description,
    _mediaUri
  );
  return mintAndListTx;
};

export const listNewPrimarySale = async (
  saleContract: Contract,
  _tokenId: BigNumber | string | number,
  _amount: BigNumber | string | number,
  _price: BigNumber | string | number,
  _token: string,
  _artist: string
): Promise<Transaction> => {
  const listNewPrimarySaleTx = await saleContract.listNewPrimarySale(
    _tokenId,
    _amount,
    _price,
    _token,
    _artist
  );
  return listNewPrimarySaleTx;
};

export const cancelPrimarySale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number
): Promise<Transaction> => {
  const cancelPrimarySaleTx = await saleContract.cancelPrimarySale(saleId);
  return cancelPrimarySaleTx;
};

export const updatePrimarySale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number,
  priceMantissa: BigNumber | string | number
): Promise<Transaction> => {
  const updatePrimarySaleTx = await saleContract.updatePrimarySale(
    saleId,
    priceMantissa
  );
  return updatePrimarySaleTx;
};

export const buyFromPrimarySale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number,
  amount: BigNumber | string | number
): Promise<Transaction> => {
  // checker for contract existence to avoid accidentally sending ETH
  const commisionWallet = await saleContract.commissionWallet();
  if (commisionWallet === ethers.constants.AddressZero) {
    throw Error('Contract does not exist!');
  }
  const primarySale = await getPrimarySale(saleContract, saleId);

  const buyFromPrimarySaleTx = await saleContract.buyFromPrimarySale(
    saleId,
    amount,
    {
      value: primarySale.price.mul(amount),
    }
  );
  return buyFromPrimarySaleTx;
};

export const listNewSale = async (
  saleContract: Contract,
  tokenId: BigNumber | string | number,
  token: string,
  tokenAmount: BigNumber | string | number,
  priceMantissa: BigNumber | string | number
): Promise<Transaction> => {
  const listNewSaleTx = await saleContract.listNewSale(
    tokenId,
    token,
    tokenAmount,
    priceMantissa
  );
  return listNewSaleTx;
};

export const cancelSale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number
): Promise<Transaction> => {
  const cancelSaleTx = await saleContract.cancelSale(saleId);
  return cancelSaleTx;
};

export const updateSale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number,
  priceMantissa: BigNumber | string | number
): Promise<Transaction> => {
  const updateSaleTx = await saleContract.updateSale(saleId, priceMantissa);
  return updateSaleTx;
};

export const buyFromSale = async (
  saleContract: Contract,
  saleId: BigNumber | string | number,
  amount: BigNumber | string | number
): Promise<Transaction> => {
  // checker for contract existence to avoid accidentally sending ETH
  const commisionWallet = await saleContract.commissionWallet();
  if (commisionWallet === ethers.constants.AddressZero) {
    throw Error('Contract does not exist!');
  }
  const sale = await getSale(saleContract, saleId);

  const buyFromSaleTx = await saleContract.buyFromSale(saleId, {
    value: sale.price.mul(amount),
  });
  return buyFromSaleTx;
};

// TODO: add auction blockchain apis

//export const listNewAuction = async (
//  saleContract: Contract,
//  tokenId: BigNumber | string | number,
//  amount: BigNumber | string | number,
//  initialPrice: BigNumber | string | number,
//  endTimestamp: BigNumber | string | number,
//  token: string
//): Promise<Transaction> => {
//  const listNewAuctionTx = await saleContract.listNewAuction(
//    tokenId,
//    amount,
//    initialPrice,
//    endTimestamp,
//    token
//  );
//  return listNewAuctionTx;
//};

//export const cancelAuction = async (
//  saleContract: Contract,
//  auctionId: BigNumber | string | number
//): Promise<Transaction> => {
//  const cancelAuctionTx = await saleContract.cancelAuction(auctionId);
//  return cancelAuctionTx;
//};

//export const sellerClaimBySig = async (
//  saleContract: Contract,
//  auctionSignature: AuctionSignature,
//  v: string,
//  r: string,
//  s: string
//): Promise<Transaction> => {
//  const sellerClaimBySigTx = await saleContract.sellerClaimBySig(
//    auctionSignature,
//    v,
//    r,
//    s
//  );
//  return sellerClaimBySigTx;
//};
