import * as Sentry from '@sentry/nextjs';

/**
 * Updates user context information for future events.
 *
 * @param user User context object to be set in the current context. Pass `null` to unset the user.
 */
const setUser: typeof Sentry.setUser = (user) => {
  return Sentry.setUser(user);
};

export default setUser;
