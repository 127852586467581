import client from '@/apis/aniftyBackend/client';

import { ListingType } from '../../aniftyBackend/mintAsks/interface';
import { SearchConditionNFTs } from '@/containers/MarketplacePage/interface';

export type RandomCreationItem = {
  saleId: number;
  aniftyTokenID: number;
  creationID: number;
  tokenName: string;
  tokenNameJA: string | null;
  imageURL: string;
  totalTokenAmount: number | null;
  availableTokenAmount: number;
  price: string;
  creationTimestamp: number;
  creatorProfile: {
    userName: string;
    displayNameEN: string;
    displayNameJP: string | null;
    profilePicUrl: string | null;
  };
  width: number;
  height: number;
  cropStartX: number;
  cropStartY: number;
  cropSize: number;
  reservedAt: string | null;
  listingType: ListingType;
  highestBidPrice: string | null;
  endTime: string | null;
};

const fetchCreationsByCreationIDs = async ({
  keyword,
  tagNames,
  priceMin,
  priceMax,
  status,
}: SearchConditionNFTs, creationIDs: string) => {
  // `keyword` param
  const params = new URLSearchParams();
  if(creationIDs) {
    params.append('creationIDs', creationIDs);
  }

  if (keyword) {
    params.append('keyword', keyword);
  }

  // add `tag_name` param if `tagNames` specified
  if (tagNames && tagNames.length) {
    tagNames.forEach((tagName) => {
      params.append('tag_name[]', tagName.toString());
    });
  }

  // `price_min` param
  if (priceMin) {
    params.append('price_min', priceMin.toString());
  }

  // `price_max` param
  if (priceMax) {
    params.append('price_max', priceMax.toString());
  }

  // `status` param
  if (status && status !== 'all') {
    params.append('status', status);
  }

  const response = await client.get<RandomCreationItem[]>(
    `api/creations/random`,
    {
      params
    }
  );
  return response.data;
};

export default fetchCreationsByCreationIDs;
