import * as Sentry from '@sentry/nextjs';

/**
 * Records a new breadcrumb which will be attached to future events.
 *
 * Breadcrumbs will be added to subsequent events to provide more context on
 * user's actions prior to an error or crash.
 *
 * @param breadcrumb The breadcrumb to record.
 */
const addBreadcrumb: typeof Sentry.addBreadcrumb = (breadcrumb) => {
  return Sentry.addBreadcrumb(breadcrumb);
};

export default addBreadcrumb;
