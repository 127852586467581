import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';

import fetchUserByID from '@/apis/aniftyBackend/users/fetchUserByID';
import { User } from '@/apis/aniftyBackend/users/interface';
import { Web3Context } from '@/contexts/Web3Provider/Web3Provider';
import setUser from '@/utils/Sentry/setUser';

const contextDefaultValue = {
  loading: true,
  currentUser: null,
  reload: () => {
    throw new Error('CurrentUserContext is not initialized yet');
  },
};

const CurrentUserContext =
  createContext<{
    loading: boolean;
    currentUser: User | null;
    reload(): Promise<void>;
  }>(contextDefaultValue);

/**
 * CurrentUserProvider
 *
 * provides current user data
 * and interface for reload
 */

type ProviderProps = {
  children: ReactNode;
};

export const CurrentUserProvider: VFC<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [currentUser, setCurrentUserState] = useState(undefined);
  const web3Context = useContext(Web3Context);

  const loadCurrentUser = async () => {
    const { web3, currentAccountAddress } = web3Context;

    // Wait for web3 initialization
    if (!web3.initialized) {
      return;
    }

    // Wallet is not connected
    if (!currentAccountAddress) {
      setCurrentUserState(null);
      return;
    }

    const user = await fetchUserByID(currentAccountAddress);
    setCurrentUserState(user);

    // Set user data to Sentry context
    setUser({
      id: currentAccountAddress,
      username: user.userName,
    });
  };

  // automatically load current user data on current account address change
  useEffect(() => {
    loadCurrentUser();
  }, [web3Context.web3.initialized, web3Context.currentAccountAddress]);

  return (
    <CurrentUserContext.Provider
      value={{
        loading: typeof currentUser === 'undefined',
        currentUser: currentUser || null,
        reload: loadCurrentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserContext;
