/* eslint-disable */
// import IPFS from 'ipfs-core';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  VFC,
} from 'react';

import {
  getWeb3Provider,
  getMetamaskProvider,
  isMetamaskConnected,
  addMetamaskListeners,
  getAccountSigner,
  // ExampleContract
} from '@/apis/blockchain/blockchainCommon';

/*
  TODO import contract functions from /api
*/
import { getAniftyERC1155Contract } from '@/apis/blockchain/AniftyERC1155';
import { getAniftyCollectionContract } from '@/apis/blockchain/AniftyCollection';
import signInUserByID from '@/apis/aniftyBackend/users/signInUserByID';
import { Contract } from 'ethers';
import { getAniftySaleContract } from '@/apis/blockchain/AniftySale';
import { getAniftyMarketplaceV2Contract } from '@/apis/blockchain/AniftyMarketplaceV2';
import { getAniftyERC1155V2IpfsContract } from '@/apis/blockchain/AniftyERC1155V2Ipfs';
import { getAniftyMarketplaceV2IpfsContract } from '@/apis/blockchain/AniftyMarketplaceV2Ipfs';
import { getWrappedETHContract } from '@/apis/blockchain/WrappedETH';
import setUser from '@/utils/Sentry/setUser';
import { captureException } from '@/utils/Sentry';
import { gaSetUser } from '@/utils/gtag';

type Web3 = {
  initialized: boolean;
  metamaskProvider: any;
  web3Provider: any;
  metamaskInstalled: boolean;
  ipfs: null;
  AniftyERC1155Contract: Contract;
  AniftyCollectionContract: Contract;
  AniftySaleContract: Contract;
  AniftyMarketplaceV2: Contract;
  AniftyERC1155V2Ipfs: Contract;
  AniftyMarketplaceV2Ipfs: Contract;
  WrappedETHContract: Contract;
};

type ContextType = {
  web3: Web3;
  currentAccountAddress: string | null;
  metamaskConnected: boolean;
  networkId: number | null;
};

// initialize with default value
const contextDefaultValue = {
  web3: {
    initialized: false,
    metamaskProvider: null,
    web3Provider: null,
    metamaskInstalled: false,
    ipfs: null,
    AniftyERC1155Contract: null,
    AniftyCollectionContract: null,
    AniftySaleContract: null,
    AniftyMarketplaceV2: null,

    AniftyERC1155V2Ipfs: null,
    AniftyMarketplaceV2Ipfs: null,
    WrappedETHContract: null,
  },
  networkId: null,

  currentAccountAddress: null,
  metamaskConnected: false,
  connectWithMetamask: () => {
    throw new Error('not initialized yet');
  },
};

//export const Web3Context = createContext<ContextType>(contextDefaultValue);
export const Web3Context = createContext<ContextType>(contextDefaultValue);

type Props = {
  children: ReactNode;
};

const Web3Provider: VFC<Props> = ({ children }: Props) => {
  const [metamaskConnected, setMetamaskConnected] = useState<boolean>(false);
  const [currentAccountAddress, setCurrentAccountAddress] =
    useState<string | null>(null);
  //const [web3, setWeb3] = useState<Web3>(contextDefaultValue.web3);
  const [web3, setWeb3] = useState<any>(contextDefaultValue.web3);
  const [networkId, setNetworkId] = useState<number>(1);

  const web3Setup: any = async () => {
    try {
      const metamaskProvider = await getMetamaskProvider();
      const web3Provider = getWeb3Provider(metamaskProvider);

      if (metamaskProvider && web3Provider) {
        addMetamaskListeners(
          metamaskProvider,
          async (chainId) => {
            setNetworkId(parseInt(chainId));
            window.location.reload();
          },
          (message: any) => {},
          async (accounts) => {
            if (accounts.length === 0) {
              setMetamaskConnected(false);
              setCurrentAccountAddress('');
            } else if (accounts[0] !== currentAccountAddress) {
              setCurrentAccountAddress(accounts[0]);
              setMetamaskConnected(true);
              try {
                await signInUserByID(accounts[0]);
              } catch (err) {
                captureException(err);
              }
            }
          }
        );

        let currentAccountAddress;
        try {
          currentAccountAddress = await (
            await getAccountSigner(web3Provider)
          ).getAddress();
        } catch (error) {
          currentAccountAddress = '';
        }

        // set user address to Sentry context
        setUser({
          id: currentAccountAddress,
        });

        setCurrentAccountAddress(currentAccountAddress);

        setMetamaskConnected(await isMetamaskConnected(metamaskProvider));
        setWeb3({
          ...web3,
          initialized: true,
          web3Provider,
          metamaskProvider,
          metamaskInstalled: true,
          AniftyERC1155Contract: await getAniftyERC1155Contract(
            web3Provider.getSigner()
          ),
          AniftyCollectionContract: await getAniftyCollectionContract(
            web3Provider.getSigner()
          ),
          AniftySaleContract: await getAniftySaleContract(
            web3Provider.getSigner()
          ),
          AniftyMarketplaceV2: await getAniftyMarketplaceV2Contract(
            web3Provider.getSigner()
          ),
          // ipfs: await IPFS.create(),
          AniftyERC1155V2Ipfs: await getAniftyERC1155V2IpfsContract(
            web3Provider.getSigner()
          ),
          AniftyMarketplaceV2Ipfs: await getAniftyMarketplaceV2IpfsContract(
            web3Provider.getSigner()
          ),
          WrappedETHContract: await getWrappedETHContract(
            web3Provider.getSigner()
          )
        });
        setNetworkId(
          // @ts-ignore
          parseInt(window.ethereum.chainId)
        );
      } else {
        // metamask is not installed
        setMetamaskConnected(false);
        setWeb3({
          ...web3,
          metamaskInstalled: false,
        });
      }
    } catch (err) {
      captureException(err);
    }
  };

  useEffect(() => {
    web3Setup();
  }, []);

  useEffect(() => {
    if (currentAccountAddress) {
      // set current address as user ID to Google Analytics
      gaSetUser(currentAccountAddress);
    }
  }, [currentAccountAddress]);

  return (
    <Web3Context.Provider
      value={{
        web3,
        currentAccountAddress,
        metamaskConnected,
        networkId,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;
