import { BigNumber, Contract, ethers, providers, Transaction } from 'ethers';

import AniftyMarketplaceV2Abi from '@/abis/AniftyMarketplaceV2';
import { addresses } from '@/constants/addresses';

export const getAniftyMarketplaceV2Contract = async (
  provider: providers.Provider | providers.JsonRpcSigner
): Promise<Contract> => {
  return new ethers.Contract(
    addresses.AniftyMarketplaceV2,
    AniftyMarketplaceV2Abi,
    provider
  );
};

export const primaryCancel = async (
  marketplaceContract: Contract,
  askOrder: any
): Promise<Transaction> => {
  const tx = await marketplaceContract.primaryCancel(askOrder);
  return await tx.wait();
};

export const primaryBuy = async (
  marketplaceContract: Contract,
  askOrder: any,
  commission: any,
  token: any,
  amount: string,
  askOrderPrice: string
): Promise<Transaction> => {
  const priceEthMantissa = BigNumber.from(askOrderPrice)
    .mul(parseInt(amount))
    .toString();

  const tx = await marketplaceContract.primaryBuy(
    askOrder,
    commission,
    token,
    parseInt(amount),
    {
      value: priceEthMantissa,
    }
  );
  return await tx.wait();
};

export const primaryClaim = async (
  marketplaceContract: Contract,
  askOrder: any,
  bid: any,
  commission: any,
  token: any
): Promise<Transaction> => {
  const tx = await marketplaceContract.primaryClaim(
    askOrder,
    bid,
    commission,
    token
  );
  return await tx.wait();
};

export const askUpdate = async (
  marketplaceContract: Contract,
  prevAskOrder: any,
  askOrder: any
): Promise<Transaction> => {
  const tx = await marketplaceContract.askUpdate(prevAskOrder, askOrder);
  return await tx.wait();
};

export const isMintAskCancelled = async (
  marketplaceContract: Contract,
  askHash: string
): Promise<boolean> => {
  return await marketplaceContract.isCancelled(askHash);
};

export const amountFilled = async (
  marketplaceContract: Contract,
  askHash: string
): Promise<BigNumber> => {
  return await marketplaceContract.amountFilled(askHash);
};
