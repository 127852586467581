import { useRouter } from 'next/router';

export type GetStaticSiteUrlType = (path: string) => string;
export type UseGetStaticSiteUrlType = () => GetStaticSiteUrlType;

const STATIC_PAGE_SITE_URL = process.env.NEXT_PUBLIC_STATIC_SITE_BASE_URL;

const useGetStaticSiteUrl: UseGetStaticSiteUrlType = () => {
  const router = useRouter();

  return (path: string) => {
    return [STATIC_PAGE_SITE_URL, getLocaleForStaticPage(router.locale), path]
      .filter(Boolean)
      .join('/');
  };
};

export default useGetStaticSiteUrl;

const getLocaleForStaticPage: (locale: string) => string | undefined = (
  locale: string
) => {
  switch (locale) {
    // The WordPress i18n plugin used to build static site does not support `zh-Hant` by default.
    // It's difficult to support `zh-Hant` so the static site uses `zh-tw` as an alternative of `zh-Hant`
    // So that convert `zh-Hant` into `zh-tw` here
    case 'zh-Hant':
      return 'zh-tw';
    case 'en':
      return undefined;
    default:
      return locale;
  }
};
