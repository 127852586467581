import { useQuery } from 'react-query';

import client from '@/apis/aniftyBackend/client';
import useAccessToken from '@/hooks/useAccessToken';

type GetUnreadNotificationCountResponseBody = {
  unreadNotificationCount: number;
};

export const getUnreadNotificationCount = async (): Promise<number> => {
  return client
    .get<GetUnreadNotificationCountResponseBody>(
      '/api/notifications/unread_count'
    )
    .then((response) => response.data.unreadNotificationCount);
};

export const useUnreadNotificationCount = () => {
  const { accessToken } = useAccessToken();

  return useQuery({
    queryKey: ['unreadNotificationCount'],
    queryFn: getUnreadNotificationCount,
    enabled: !!accessToken,
    refetchInterval: 1000 * 60, // in ms (it will be automatically fetched every one minutes)
  });
};
