import client from '@/apis/aniftyBackend/client';

import { PostAccessTokenRequest, PostAccessTokenResponse } from './interface';

export const postAccessToken = async (
  request: PostAccessTokenRequest
): Promise<PostAccessTokenResponse> => {
  const response = await client.post('/api/access_token', request);
  return response.data;
};
