import client from '@/apis/aniftyBackend/client';
import { User } from '@/apis/aniftyBackend/users/interface';

const fetchUserByID = async (userID: string): Promise<User> => {
  const response = await client.get<User>(`api/users/${userID}`);

  return response.data;
};

export default fetchUserByID;
