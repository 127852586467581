import { useRouter } from 'next/router';

export type GetBlogSiteUrlType = (path: string) => string;
export type UseGetBlogSiteUrlType = () => GetBlogSiteUrlType;

const BLOG_PAGE_SITE_URL = process.env.NEXT_PUBLIC_BLOG_SITE_BASE_URL;

const useGetBlogSiteUrl: UseGetBlogSiteUrlType = () => {
  const router = useRouter();

  return (path: string) => {
    return [BLOG_PAGE_SITE_URL, path, getLocaleForBlogPage(router.locale)]
      .filter(Boolean)
      .join('/');
  };
};

export default useGetBlogSiteUrl;

const getLocaleForBlogPage: (locale: string) => string | undefined = (
  locale: string
) => {
  switch (locale) {
    // The WordPress i18n plugin used to build static site does not support `zh-Hant` by default.
    // It's difficult to support `zh-Hant` so the static site uses `zh-tw` as an alternative of `zh-Hant`
    // So that convert `zh-Hant` into `zh-tw` here
    case 'zh-Hant':
      return 'zh-tw';
    case 'en':
      return undefined;
    default:
      return locale;
  }
};
