import client from '@/apis/aniftyBackend/client';
import { UserWithProfile } from '@/apis/aniftyBackend/users/interface';

const fetchUserProfileByID = async (
  userID: string
): Promise<UserWithProfile> => {
  const response = await client.get<UserWithProfile>(
    `api/users/${userID}/profile`
  );

  return response.data;
};

export default fetchUserProfileByID;
