import { useQuery } from 'react-query';

import client from '@/apis/aniftyBackend/client';
import { AskStatus } from '@/apis/aniftyBackend/marketplaceV2/interface';
import { MintBid } from '@/apis/aniftyBackend/mintBids/interface';
import useAccessToken from '@/hooks/useAccessToken';
import useWalletAddress from '@/hooks/useWalletAddress';
import { QueryConfig } from '@/utils/react-query';

export type MintBidWithCreation = MintBid & {
  askStatus: AskStatus;
  creation: {
    creationID: number;
    imageURL: string;
    creationTitle: string;
    creationTitleJA: string | null;
    creatorName: string;
    creatorNameJA: string | null;
    creatorProfileImageURL: string | null;
  };
};

type GetMintBidsResponse = MintBidWithCreation[];

export const getCurrentUserMintBids =
  async (): Promise<GetMintBidsResponse> => {
    const response = await client.get<GetMintBidsResponse>(`api/mint_bids`, {});
    return response.data;
  };

type QueryFnType = typeof getCurrentUserMintBids;

type UseCurrentUserMintBidsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCurrentUserMintBids = ({
  config,
}: UseCurrentUserMintBidsOptions = {}) => {
  const { currentAccountAddress } = useWalletAddress();
  const { accessToken } = useAccessToken();

  return useQuery({
    ...config,
    enabled: !!currentAccountAddress && !!accessToken,
    queryKey: ['mintBids', currentAccountAddress],
    queryFn: getCurrentUserMintBids,
  });
};
