import * as jwt from 'jsonwebtoken';
import { parseCookies } from 'nookies';

import hasExpired from '@/apis/cookies/accessToken/hasExpired';
import { AccessTokenPayload } from '@/apis/cookies/accessToken/interface';
import { ACCESS_TOKEN_COOKIE_NAME } from '@/constants/cookies';

// get token from cookie
const getAccessToken = (): string | null => {
  const cookies = parseCookies();
  const accessToken = cookies[ACCESS_TOKEN_COOKIE_NAME];

  if (!accessToken) {
    return null;
  }

  try {
    const { exp } = jwt.decode(accessToken) as AccessTokenPayload;

    if (hasExpired(exp)) {
      return null;
    }

    return accessToken;
  } catch (err) {
    return null;
  }
};

export default getAccessToken;
